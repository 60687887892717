import React from 'react'
import Footer from '../Footer'
import Header from '../Header'
import styles from './HomePlaceholder.module.css'

export default function HomePlaceholder () {
	return (
		<div className={styles.root}>
			<Header />

			<div className={styles.content}>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<br/>
				<img src={require('../img/anthem/webby-logo.svg').default} alt="Webby Awards" style={{ width: 500 }} />

				<br/>

				{/* <div className={styles.topText}>The 3rd Annual</div> */}

				{/* <div className={styles.text} /> */}

				{/* <div className={styles.bottomText}> */}
				{/*	Coming Soon */}
				{/* </div> */}

				<div className={styles.moreText}>
					<p>
						Honoring the best of the Internet.
					</p>

					<p>
						Winners will be publicly announced on Monday, May, 13th! The announcement will showcase the best
						websites, videos, advertising, podcasts, social, games and more along with Webby Five-Word
						Speeches from all of our Winners!
					</p>

					<br/>
					<br/>
					<br/>

					{/* <p style={{ textTransform: 'uppercase', fontSize: 26 }}> */}
					{/*	Monday, February 28, 5pm ET */}
					{/* </p> */}
				</div>
			</div>

			<Footer/>
		</div>
	)
}
