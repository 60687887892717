import React, { useEffect } from 'react'
import {
	BrowserRouter as Router,
	Switch,
	Route,
	useLocation
} from 'react-router-dom'
import Browse from './browse/Browse'
import App from './App'
import Home from './home/Home'
import Speech from './browse/Speech'
import Vote from './Vote'
import GodMode from './GodMode'

import HomePlaceholder from './home/HomePlaceholder'

function ScrollToTop () {
	const { pathname } = useLocation()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	return null
}

export default function R () {
	const prefix = 'preview-hide'
	return (
		<Router>
			<ScrollToTop />
			<Switch>
				<Route exact path="/emily-god-mode">
					<GodMode />
				</Route>

				<Route exact path={`${prefix}/browse`}>
					<Browse />
				</Route>

				<Route exact path={`${prefix}/search/:term`}>
					<Browse />
				</Route>

				<Route exact path={`${prefix}/search`}>
					<Browse />
				</Route>

				<Route exact path="/upload/:EmailID"><App official={true} /></Route>

				<Route exact path="/watch/:id"><Speech /></Route>

				<Route exact path={`${prefix}/`}>
					<Home />
				</Route>

				<Route path="/">
					<HomePlaceholder />
				</Route>
			</Switch>
		</Router>
	)
}
