import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styles from './VideoList.module.scss'
import Config from '../Config'

export default function VideoList ({ pieceID }) {
	const [init, setInit] = useState(false)
	const [videos, setVideos] = useState([])

	const getStuff = async () => {
		const v = await axios.get(
			`${Config.endpoint}/getVideosWebby2024?pieceID=${pieceID}`,
		)

		if (v.data.length > 0) {
			setVideos(v.data)
		}

		setInit(true)
	}

	useEffect(() => {
		getStuff()
	}, [pieceID])

	return (
		<div className={styles.root}>
			{videos.length === 0 && init ? <div>No Speech Recorded</div> : null}
			{videos.map((video, v) => {
				return video.status === false ? (
					<div key={v}>
            Video Encoding... {video.official ? <em>Official video</em> : null}
					</div>
				) : (
					<div key={v}>
            ✅{' '}
						<a href={`/watch/${video.id}`} rel="noreferrer" target="_blank">
              Speech Recorded
						</a>
					</div>
				)
			})}
			{!init ? <div>Checking speech...</div> : null}
		</div>
	)
}
