const flow = [
	// opening 16x9
	{
		title: 'Jay Ellis',
		subtitle: 'An Anthem Welcome From Your Host',

		hash: 'anthem-2022-opening',
		videoID: 'monologue',
		presenter: null,
		winner: null,
		prefix: '',

		needsWatch: true
	},

	{
		title: 'Dr. Jane Goodall',
		subtitle: 'Bindi Irwin Presents to',

		hash: 'anthem-2022-jane-goodall-lifetime-achievement',
		videoID: 'janegoodall',
		presenter: null,
		winner: null,
		prefix: 'Anthem Lifetime Achievement',

		needsWatch: true
	},

	{
		title: 'Naomi Osaka',
		subtitle: 'Jay Ellis Presents to',

		hash: 'anthem-2022-sa-osaka',
		videoID: 'naomiosaka',
		presenter: null,
		winner: null,
		prefix: 'Anthem Athlete of the Year',

		needsWatch: true
	},

	{
		title: 'Adam McKay',
		subtitle: 'Jay Ellis Presents to',
		hash: 'anthem-2022-sa-adammckay',
		videoID: 'adammckay',
		presenter: null,
		winner: null,
		prefix: 'Anthem Entertainer of the Year'
	},
	{
		title: 'Megan Rapinoe',
		subtitle: 'Jay Ellis Presents to',

		hash: 'anthem-2022-new-icon',
		videoID: 'meganrapinoe',
		presenter: null,
		winner: null,
		prefix: 'Anthem New Icon',

		needsWatch: true
	},

	{
		title: 'The Daily Show With Trevor Noah',
		subtitle: 'Trevor Noah For',

		hash: 'dc634844-7c17-4368-a745-798134f9d6db',
		videoID: 'rUxNupdvVVFbaqA67RQW',
		presenter: null,
		winner: null,
		prefix: 'Gold | Special Projects'
	},

	{
		title: 'Thriver Thursday: Season 4',
		subtitle: 'Robin Roberts for',

		hash: '749ed5e5-15a5-4c91-8369-dfc6ad1185e4',
		videoID: 'HE3EDruiUP6wHuP2cBXU',
		presenter: null,
		winner: null,
		prefix: 'Silver | Health Special Projects'
	},

	{
		title: 'Sesame Workshop',
		subtitle: 'Cast of Sesame Street for',

		hash: 'a0c8c7c3-5818-491c-b0e5-3edb06d471f7',
		videoID: 'w92HkZcfxzXxGxWcimP0',
		presenter: null,
		winner: null,
		prefix: 'Gold | Special Projects',

		needsWatch: true
	},
	{
		title: 'Solutions Project',
		subtitle: 'Best Strategy',

		hash: '33c93208-e9f8-4094-9915-8175779a4d67',
		videoID: '4N5mHn2GOGKrMRMQOwqr',
		presenter: null,
		winner: null,
		prefix: 'Gold | Sustainability',

		needsWatch: true
	},
	{
		title: 'UN Foundation',
		subtitle: 'Campaign - Non-Profit',

		hash: '9402fa70-4c7f-463e-b8cf-b2c79d0d7703',
		videoID: 'RLP6sN61ovNmmWuVtQIt',
		presenter: null,
		winner: null,
		prefix: 'Bronze | Human & Civil Rights',

		needsWatch: true
	},
	{
		title: 'Elle Communications',
		subtitle: 'Campaign - Non-Profit',

		hash: '85cfef91-a324-42fd-8814-1519c51db264',
		videoID: 'P5TQpKQ6iwcbnIMnI0vJ',
		presenter: null,
		winner: null,
		prefix: 'Gold | Human & Civil Rights'
	},
	{
		title: 'The Anthem Awards',
		subtitle: 'A Message From',

		hash: 'anthem-2022-message',
		videoID: 'message',
		presenter: null,
		winner: null,
		prefix: ''
	},
	{
		title: 'The Creative Coalition',
		subtitle: 'LeVar Burton for',

		hash: 'cfd580ba-a03b-430e-b416-42d679373ec5',
		videoID: 'OR09vuEirivlj1NHCMtq',
		presenter: null,
		winner: null,
		prefix: 'Bronze | Campaign - Non-Profit',

		needsWatch: true
	},
	{
		title: 'Google',
		subtitle: 'Campaign - Brand',

		hash: 'ba35506b-b755-4a91-b73b-f4277de8bb1b',
		videoID: 'YAHpwAKuMPIALnuYk5kz',
		presenter: null,
		winner: null,
		prefix: 'Gold | Responsible Tech'
	},
	{
		title: '#STOPASIANHATE',
		subtitle: 'Community Outreach',

		hash: '16b3660a-22b3-479d-9c21-bcea49e5528e',
		videoID: 'dEM3qi2gOV6Ew2sy2mTW',
		presenter: null,
		winner: null,
		prefix: 'Bronze | Human & Civil Rights',

		needsWatch: true
	},
	{
		title: 'Planned Parenthood',
		subtitle: 'Innovation',

		hash: '54858985-b70a-46cf-bceb-85603a5a4b7a',
		videoID: 'TFmdSrLXuiOm4jNsf500',
		presenter: null,
		winner: null,
		prefix: 'Gold | Health',

		needsWatch: true
	},
	{
		title: 'FOLX Health',
		subtitle: 'Innovation',

		hash: 'e99bcc5d-068b-4de8-b28d-3d4ce447e1a8',
		videoID: 'LDs0fO3VlCEpwEPZgEpR',
		presenter: null,
		winner: null,
		prefix: 'Gold | Diversity, Equity & Inclusion'
	},
	{
		title: 'MIT Open Documentary Lab',
		subtitle: 'Special Projects',

		hash: 'cfde3918-28d1-42bf-ad50-21b2726233cd',
		videoID: 'oOztM39g1cnihgeFws7A',
		presenter: null,
		winner: null,
		prefix: 'Silver | Responsible Tech'
	},
	{
		title: 'IFundWomen',
		subtitle: 'Best Fundraising Model',

		hash: '2bab3502-0ba4-4a4f-8586-4637419e955d',
		videoID: 'J0CE66ybteHQ7M0nlIpx',
		presenter: null,
		winner: null,
		prefix: 'Gold | Diversity, Equity & Inclusion',

		needsWatch: true
	},
	{
		title: 'JDS Creative Academy',
		subtitle: 'Best Local Community Engagement',

		hash: '0a26bcd8-6c6b-4fdb-b94f-a4bf680fdd50',
		videoID: 'fD5KFkuNzzIuRh2lMxS0',
		presenter: null,
		winner: null,
		prefix: 'Bronze | Diversity, Equity & Inclusion',

		needsWatch: true
	},
	{
		title: 'The New York Times',
		subtitle: 'Special Projects',

		hash: '80c24be1-5eb9-4a9c-b59a-f85f42deee38',
		videoID: 'r13JiD4CNGcLHqwta7k1',
		presenter: null,
		winner: null,
		prefix: 'Silver | Diversity, Equity & Inclusion',

		needsWatch: true
	},
	{
		title: 'JustFix',
		subtitle: 'Local Community Engagement',

		hash: '2b2be407-3dd0-4d72-b7a9-6c9e8bf2ac55',
		videoID: 'BCMiH4cyMmO31YNytJP2',
		presenter: null,
		winner: null,
		prefix: 'Gold | Responsible Tech ',

		needsWatch: true
	},
	{
		title: 'NOAA Ocean Today',
		subtitle: 'Event',

		hash: 'c2e12b1e-41ed-4214-8670-d4ed34acb34c',
		videoID: '7LPE3ve3XBoLnlJaDkFc',
		presenter: null,
		winner: null,
		prefix: 'Silver | Sustainability, Environment & Climate',

		needsWatch: true
	},
	{
		title: 'Freedom Futures Collective',
		subtitle: 'Best Local Community Engagement',
		hash: '3e75054f-11b6-43ef-be3a-f06082f2923a',
		videoID: '7cEQ9mVgiPsgmTkazUmp',
		prefix: 'Silver | Education, Art, & Culture',
	},

	{
		title: 'Consumer Reports',
		subtitle: 'Innovation',
		hash: 'ca5892ad-539e-41e2-abaa-739eb2ee5b70',
		videoID: '4smUwA2Tr3S62gUs8ofU',
		prefix: 'Silver | Responsible Tech',
	},
	{
		title: 'The Possibility Project',
		subtitle: 'Best Local Community Engagement',
		hash: '984eb7be-b8b0-4cce-ba0e-345e5e5a386c',
		videoID: 'JQ6G9FaznmRN98OfbhRc',
		prefix: 'Silver | Education, Art, & Culture',
	},
	{
		title: 'Pillars Fund',
		subtitle: 'Strategy',
		hash: 'e2eb6bde-7d19-466a-bb61-9b52cfe7f605',
		videoID: 'QwJ9gPUYP9VyhWztmT4A',
		prefix: 'Silver | Diversity, Equity & Inclusion',
	},

	{
		title: 'Plastic Fisherman',
		subtitle: 'Community Outreach',
		hash: '1659bbdb-646f-4196-b2f1-24caa1c35305',
		videoID: 'kmmkMvFAljM18V2pG0iy',
		prefix: 'Silver | Sustainability, Environment & Climate',
	},
	{
		title: 'Innocence Project',
		subtitle: 'Campaign - Brand',
		hash: '27c0a3b1-a2c9-42d1-8295-d8b1a1795083',
		videoID: 'mMup6pHgXZFePwQx9S9r',
		prefix: 'Gold | Humanitarian Action & Services',
	},
	{
		title: 'Malala Fund',
		subtitle: 'Networking or Community Organizations',
		hash: '4946625b-f746-45ec-a180-9d35813aa4a4',
		videoID: '4eCGANwJELUvHta2HL2j',
		prefix: 'Gold | Diversity, Equity & Inclusion',
	},

	{
		title: 'Jay Ellis',
		subtitle: 'A Closing Message From',

		hash: 'anthem-2022-closing',
		videoID: 'closing',
		presenter: null,
		winner: null,
		prefix: '',

		needsWatch: true
	}
]

// module.exports = flow
export default flow

export function flowIndex (id) {
	return flow.indexOf(findInFlow(id))
}

export function findInFlow (id) {
	return flow.find(v => v.videoID === id)
}

export function flowSlice (left, right) {
	return flow.slice(flowIndex(left) + 1, right && flowIndex(right))
}
