import React from 'react'

export default function Help () {
	return <>
		<br />
		<div>
			Evey Long and her team are available to answer any questions right now<br />
			Email: <a
				style={{ color: 'white' }}
				href="mailto:evey@webbyawards.com">evey@webbyawards.com</a><br />
			Call: +1 212 675 3555<br />
		</div>

		<br />
	</>
}
