import React, { useState, useEffect } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import BrowseCell from './BrowseCell'
import { Link, useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import Analytics from '../Analytics'

import Config from '../Config'
import Header from '../Header'
import Footer from '../Footer'
import Rivers from './Rivers'

import Loader from '../recorder/Loader'
import { findInFlow } from '../home/ShowFlow'

// import { useLocalStorage } from 'usehooks-ts'
const what = null
console.log(what?.the)

const firebaseConfig = {
	apiKey: 'AIzaSyBYWeaP4EOl0gVh59sj-d0BgSnOpJhQaHg',
	authDomain: 'webbyreport-811f9.firebaseapp.com',
	databaseURL: 'https://webbyreport-811f9.firebaseio.com',
	projectId: 'webbyreport-811f9',
	storageBucket: 'webbyreport-811f9.appspot.com',
	messagingSenderId: '1029956129168',
	appId: '1:1029956129168:web:b865f4a6d14ef5bf29e315',
}

if (!firebase.apps.length) {
	firebase.initializeApp(firebaseConfig)
}

const db = firebase.firestore()

export default function Browse () {
	const { term } = useParams()
	const history = useHistory()

	const CancelToken = axios.CancelToken
	const source = CancelToken.source()

	const [searchTerm, setSearchTerm] = useState(term)
	const [playlists, setPlaylistsReal] = useState(null)
	const [results, setResults] = useState([])
	const [searching, setSearching] = useState(false)

	const handleChange = (event) => {
		source.cancel()
		setSearchTerm(event.target.value)
		history.push(`/search/${encodeURIComponent(event.target.value)}`)
	}

	const setPlaylists = (val) => {
		setPlaylistsReal(val)
		window.localStorage.setItem('playlistsCachetime', new Date())
		window.localStorage.setItem('playlists', JSON.stringify(val))
	}

	const search = async () => {
		setSearching(true)

		let ppp = null
		if (!playlists) {
			ppp = await getRivers()
		}

		if (searchTerm !== '' && searchTerm) {
			/* if(searchTerm.length <= 2) {
                setResults([]);
                setSearching(false);
                return
            } */

			try {
				// weird race conditions shit...
				if (playlists) {
					ppp = playlists
				}

				// const results = await axios.get('https://us-central1-webbyreport-811f9.cloudfunctions.net/searchLovies?query='+(term), {
				//    cancelToken: source.token
				// });
				// setResults(results.data);
				const terms = searchTerm.toLowerCase().split(' ')
				const results = []
				for (let x = 0; x < ppp.length; x++) {
					for (let y = 0; y < ppp[x].videos.length; y++) {
						const cell = ppp[x].videos[y]
						for (let k = 0; k < cell.keywords.length; k++) {
							let found = false
							for (let term = 0; term < terms.length; term++) {
								if (cell.keywords[k].indexOf(terms[term]) >= 0) {
									found = true
								}
							}

							if (found) {
								results.push(cell)
							}
						}
					}
				}

				setResults(results)
				setSearching(false)
			} catch (err) {
				console.log(err)
			}
		} else {
			setResults([])
			setSearching(false)
		}
	}

	const getVideosInPlaylistLovies = async (playlist) => {
		return new Promise((resolve, reject) => {
			db.collection('webby_videos_2024')
				.where('official', '==', true)
				.where('playlists', 'array-contains', playlist)
				.get()
				.then((snapshot) => {
					if (snapshot.empty) {
						resolve([])
					} else {
						const videos = []
						snapshot.forEach(async (doc) => {
							const obj = doc.data()
							obj.id = doc.id
							videos.push(obj)
						})
						resolve(videos)
					}
				})
				.catch((err) => console.log(err))
		})
	}

	const getRivers = async () => {
		const cacheValue = window.localStorage.getItem('playlists')

		if (cacheValue) {
			setPlaylists(JSON.parse(cacheValue))
		}

		// const results = await axios.get('https://us-central1-webbyreport-811f9.cloudfunctions.net/getPlaylistsLovies');

		// const pl = await db.collection('defaults').doc('lovies_browse').get()
		// const playlists = pl.data().playlists
		const playlists = [
			'Diversity, Equity, & Inclusion',
			'Education, Art, & Culture',
			'Health',
			'Human & Civil Rights',
			'Humanitarian Action & Services',
			'Responsible Technology',
			'Sustainability, Environment, & Climate',
		]

		const final = []

		for (let x = 0; x < playlists.length; x++) {
			const obj = await getVideosInPlaylistLovies(playlists[x])
			final.push({ name: playlists[x], videos: obj })
		}

		setPlaylists(final)

		return final
	}

	useEffect(() => {
		setResults([])
		setSearchTerm(term)
	}, [term])

	useEffect(() => search(), [searchTerm])

	useEffect(() => {
		if (!playlists) {
			const z = getRivers()
		}

		Analytics.sendEvent('Browse Page')
	}, [])

	return (
		<>
			<Header section="browse" />

			<div className="browse">
				<div className="browse-header">
					<div>
						<br />
						<div className="browse-header-pre">The Inaugural</div>
						<br />
						<div className="browse-gl">
							<img
								src={
									require('../img/anthem/anthem-landing-logo-color.svg').default
								}
								alt="Anthem Awards"
								style={{ width: '90%', maxWidth: 600 }}
							/>
						</div>
						<br />
						<div className="browse-header-bottom">
							<div className="browse-header-sub" style={{ fontSize: 24 }}>
                Hosted by Jay Ellis
							</div>
						</div>
						<br />
					</div>
				</div>

				<div className="browse-searchbar">
					<div>
						<input
							type="text"
							placeholder={
								window.innerWidth < 700
									? 'Search'
									: 'Search All Speeches and Moments'
							}
							value={decodeURIComponent(searchTerm || '')}
							onChange={handleChange}
						/>
						<Link to="/" className={!searchTerm ? 'clear clear-hide' : 'clear'}>
              Clear Search
						</Link>
					</div>
				</div>

				{(!searchTerm || searchTerm === '') &&
          [
          	[
          		findInFlow('janegoodall'),
          		findInFlow('naomiosaka'),
          		findInFlow('adammckay'),
          		findInFlow('meganrapinoe'),
          	],
          	[
          		findInFlow('rUxNupdvVVFbaqA67RQW'),
          		findInFlow('w92HkZcfxzXxGxWcimP0'),
          		findInFlow('OR09vuEirivlj1NHCMtq'),
          		findInFlow('4N5mHn2GOGKrMRMQOwqr'),
          	],
          ].map((row, i) => (
          	<div className="browse-grid" key={i}>
          		{row.map((tile) => (
          			<div
          				key={tile.videoID}
          				className="browse-grid-cell"
          				style={{
          					backgroundImage: `url(${Config.bucketURL}${tile.hash}.jpg)`,
          				}}
          			>
          				<Link to={`/watch/${tile.videoID}`}>
          					<div className="play-btn"></div>
          					<div className="home-video-meta">
          						<div className="home-video-meta-sub" role={tile.prefix}>
          							{tile.subtitle} <strong>{tile.title}</strong>
          						</div>
          					</div>
          				</Link>
          			</div>
          		))}
          	</div>
          ))}

				<div
					className={
						!searchTerm
							? 'browse-content browse-river'
							: 'browse-content browse-search'
					}
				>
					{results &&
            results.map((result, r) => <BrowseCell key={r} data={result} />)}

					{searching && searchTerm ? (
						<Loader text="Searching..." />
					) : results.length === 0 && searchTerm ? (
						<div className="browse-nothing">
							<div>No Results Found</div>
						</div>
					) : null}

					{!searchTerm || searchTerm === '' ? (
						playlists ? (
							<Rivers playlists={playlists} />
						) : (
							<Loader text="Loading playlists..." />
						)
					) : null}
				</div>
			</div>
			<Footer />
		</>
	)
}
