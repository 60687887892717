import React, { Suspense, useEffect, useMemo, useRef } from 'react'
import * as THREE from 'three'
import { Canvas, useThree, useLoader, useFrame } from '@react-three/fiber'
import { Extrude, OrbitControls, Center } from '@react-three/drei'
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader.js'
// eslint-disable-next-line import/no-webpack-loader-syntax
import svgString from '!raw-loader!../img/anthem/aako.svg'
import { TextureLoader } from 'three/src/loaders/TextureLoader'

const textureImageContext = require.context('./Floater/texture/', true, /\.jpg/)

const loader = new SVGLoader()
const svgData = loader.parse(svgString)
// const svgGroup = new THREE.Group();
// const updateMap = [];

const DEG2RAD = 0.0174533
const extrudeSettings = { steps: 2, depth: 100, bevelEnabled: true }
const SIDE = 10

// const computeGroupCenter = (function () {
// 	const childBox = new THREE.Box3()
// 	const groupBox = new THREE.Box3()
// 	const invMatrixWorld = new THREE.Matrix4()
// 	return function (group, optionalTarget) {
// 		if (!optionalTarget) optionalTarget = new THREE.Vector3()
// 		group.traverse(function (child) {
// 			if (child instanceof THREE.Mesh) {
// 				if (!child.geometry.boundingBox) {
// 					child.geometry.computeBoundingBox()
// 					childBox.copy(child.geometry.boundingBox)
// 					child.updateMatrixWorld(true)
// 					childBox.applyMatrix4(child.matrixWorld)
// 					groupBox.min.min(childBox.min)
// 					groupBox.max.max(childBox.max)
// 				}
// 			}
// 		})
// 		// All computations are in world space
// 		// But the group might not be in world space
// 		group.matrixWorld.getInverse(invMatrixWorld)
// 		groupBox.applyMatrix4(invMatrixWorld)
// 		groupBox.getCenter(optionalTarget)
// 		return optionalTarget
// 	}
// })()

function Block (props) {
	const shapes = React.useMemo(() => {
		const shapes = []

		svgData.paths.forEach((path) => {
			const _shapes = SVGLoader.createShapes(path)

			_shapes.forEach((shape) => {
				shapes.push(shape)
			})
		})

		return shapes
	}, [])

	// const [colorMap, displacementMap, normalMap, roughnessMap, metalnessMap] = useLoader(TextureLoader, [
	// 	textureImageContext('./Metal007_1K_Color.jpg').default,
	// 	textureImageContext('./Metal007_1K_Displacement.jpg').default,
	// 	textureImageContext('./Metal007_1K_NormalGL.jpg').default,
	// 	textureImageContext('./Metal007_1K_Roughness.jpg').default,
	// 	textureImageContext('./Metal007_1K_Metalness.jpg').default
	// ])

	const group = useRef(null)
	const outerGroup = useRef(null)
	const geometry = useRef(null)

	useEffect(() => {
		if (!group.current) return

		group.current.rotation.x = 180 * DEG2RAD

		group.current.traverse(child => {
			if (child instanceof THREE.Mesh) {
				// debugger
				child.position.x = -400
			}
		})
	}, [group.current])

	useFrame(() => {
		group.current.rotation.y += 0.01
		outerGroup.current.position.x += 0.1 // + Math.abs(Math.sin(group.current.rotation.y))
	})

	const scale = 0.05

	return (
		<group ref={outerGroup} scale={new THREE.Vector3(scale, scale, scale)} position={ new THREE.Vector3(-80, 22, 0)}>
			<group ref={group}>
				{shapes.map((shape, i) => (
					<Extrude
						ref={geometry}
						key={i}
						args={[shape, extrudeSettings]}
						{...props}
					>
						{/* <meshPhysicalMaterial
							// flatShading
							color="#3E64FF"
							thickness={SIDE}
							roughness={0.4}
							clearcoat={1}
							clearcoatRoughness={1}
							// transmission={0.8}
							ior={1.25}
							attenuationTint="#fff"
							attenuationDistance={0}
						/> */}
						<meshStandardMaterial
							color="#f79b33"
							// map={colorMap}
							// displacementMap={displacementMap}
							// displacementScale={0.1}
							// normalMap={normalMap}
							// normalScale={new THREE.Vector2(10, 10)}
							// roughnessMap={roughnessMap}
							// metalnessMap={metalnessMap}
							metalness={1}
							roughness={1}
						/>

						{/* <meshPhongMaterial color="#049ef4" /> */}
					</Extrude>
				))}
			</group>
		</group>
	)
}

function Scene () {
	const intensity = 1

	return (
		<Canvas
			dpr={window.devicePixelRatio}
			camera={{ position: new THREE.Vector3(-10, 0, 200), fov: 20 }}
			// height={500}
		>
			{/* <color attach="background" args={['#06092c']} /> */}
			<pointLight position={[-20, 10, 25]} intensity={intensity} />
			<pointLight position={[80, 10, 25]} intensity={intensity} />
			<pointLight position={[180, 10, -100]} intensity={intensity} />
			<pointLight position={[-180, -10, -100]} intensity={intensity} />
			{/* <gridHelper
				args={[100, 20, '#4D089A', '#4D089A']}
				position={[0, 0, -10]}
				rotation={[-Math.PI / 2, 0, 0]}
			/> */}
			{/* <ambientLight intensity={2} />
			<directionalLight intensity={10}/> */}

			{/* <Center> */}
			<Block />
			{/* </Center> */}

			{/* <FlipCam /> */}
		</Canvas>
	)
}

function FlipCam () {
	const defaultCamera = useThree(({
		camera
	}) => camera)

	const camera = useMemo(() => {
		defaultCamera.up.set(0, -1, 0)
		return defaultCamera
	})

	return <OrbitControls autoRotate autoRotateSpeed={5} camera={camera} enableRotate={false} enableZoom={false} enablePan={false} />
}

export default function Suspender () {
	return <Suspense fallback={<div />}>
		<Scene />
	</Suspense>
}
