import React, { useState } from 'react'
import Identify from '../utils/Identify'
// import VideoCapture from './VideoCapture/VideoCapture';
import Progress from './Progress'
import Analytics from '../Analytics'
import Help from './Help'
import styles from './ScreenRecorder.module.scss'
import Button from './Button'

export default function ScreenRecorder ({ goHome, piece = null, section, currentSection, setShow, official }) {
	const identifyInstance = new Identify()
	const ios = identifyInstance.mobileOS() === 'iOS' || identifyInstance.mobileOS() === 'Android'
	const safari = identifyInstance.safari()
	const [enable, setEnable] = useState(null)

	const [progress, setProgress] = useState(1)

	const finalize = (videoID) => {
		setShow(videoID)
	}

	return <>
		<div
			onClick={goHome}
			className={styles.backButton}
		>Back to Winning Project</div>

		<div className="section-recorder flex">
			<div className="section-wide recorder">
				<div>
					<div className="flex">
						<div className="p-top">
							<p>
								<strong>Upload Your Webby Five-Word Speech</strong>
							</p>
							<h1>{piece ? piece.PieceTitle : null}</h1>
							<p>
								<strong>What happens next?</strong>
							</p>
							<p>
								Upload your Webby Five-Word video here and it will be posted to your Winners Gallery Page at webbyawards.com on May 13th.
							</p>

							<div>
								<br />
								<br />
								<Button wide onClick={() => setShow()}>I&apos;m Ready to Upload</Button>
							</div>
							<br />
							<br />
							{/* <Help /> */}
						</div>
					</div>
				</div>
			</div>
			<Progress progress={progress} />
		</div>
	</>
}
