import React from 'react'

import Twitter from './img/twitter.svg'
import Facebook from './img/facebook.svg'
import Instagram from './img/instagram.svg'
import Linkedin from './img/linkedin.svg'

import PartnerList from './PartnerList'
import styles from './Footer.module.scss'

import Webbys from './img/anthem/webby-logo.svg'

export default function Footer ({ full = true }) {
	return (
		<>
			{full && (
				<footer>
					<PartnerList />
					<div className={styles.root}>
						<div className={styles.left} style={{ width: 140 }}>
						</div>

						<div className={styles.center}>
							<div className={styles.social}>
								<a
									href="https://twitter.com/thewebbyawards"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={Twitter} alt="Twitter" />
								</a>
								<a
									href="https://www.facebook.com/thewebbyawards"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={Facebook} alt="Facebook" />
								</a>
								<a
									href="https://www.instagram.com/thewebbyawards/"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={Instagram} alt="Instagram" />
								</a>
								<a
									href="https://www.linkedin.com/company/the-webby-awards"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={Linkedin} alt="LinkedIn" />
								</a>
							</div>

							<div className={styles.links}>
								<a
									href="https://www.webbyawards.com/contact-us/"
									target="_blank"
									rel="noopener noreferrer"
								>
									Contact Us
								</a>
								<a
									href="http://www.iadas.net/"
									target="_blank"
									rel="noopener noreferrer"
								>
									IADAS
								</a>
								<a
									href="https://www.webbyawards.com/faq/"
									target="_blank"
									rel="noopener noreferrer"
								>
									FAQ
								</a>
								<a
									href="https://www.webbyawards.com/privacy/"
									target="_blank"
									rel="noopener noreferrer"
								>
									Privacy Policy
								</a>
							</div>
						</div>

						<div className={styles.right}>
							Presented by <br />
							<a href="https://www.webbyawards.com/" target="_blank" rel="noopener noreferrer">
								<img src={Webbys} />
							</a>
						</div>
					</div>
				</footer>
			)}
		</>
	)
}
