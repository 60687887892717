import React from 'react'
import styles from './Button.module.scss'
import c from 'classnames'

const Button = ({ children, nopad = false, italic = false, wide = false, outline = false, ...props }) =>
	React.createElement(
		props.href ? 'a' : 'div',
		{
			...props,
			className: c(styles.root, {
				[styles.nopad]: nopad,
				[styles.italic]: italic,
				[styles.wide]: wide,
				[styles.outline]: outline
			})
		},
		children
	)

export default Button
