import React, { useState, useRef, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
// import Config from '../Config'
import Analytics from '../Analytics'
import Help from './Help'
import Loader from './Loader'
import styles from './ScreenHome.module.scss'
import c from 'classnames'
import Button from './Button'
import Config from '../Config'

export default function ScreenHome ({ section, currentSection, login }) {
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)
	const { EmailID } = useParams()
	const id = useRef()

	const validate = async () => {
		if (id.current.value) {
			setLoading(true)
			const data = await axios.post(`${Config.endpoint}/webby_login2024`, {
				entrantID: id.current.value,
				emailID: EmailID,
			})

			setLoading(false)

			if (data.data.user) {
				Analytics.sendLogin(data.data.user.EntrantID)
				login(data.data.user)
			} else {
				setError('Invalid Entrant ID')
				Analytics.sendEvent('Invalid Login')
			}
			// login(id.current.value);
		} else {
			setError('You need to enter your entrant ID')
		}
	}

	const kp = (e) => {
		setError(null)
		if (e.key === 'Enter') {
			validate()
		}
	}

	useEffect(() => Analytics.sendEvent('Recorder Login Page'), [])

	return (
		<>
			<div className={styles.sectionHome} >
				<div className="section-wide">
					<h1 style={{ textTransform: 'none' }}>
						{/* Congratulations! */}
						<br />
						<br />
						<span style={{ fontWeight: 'normal' }}>
							Welcome to The 28th Annual Webby Awards Speech Uploader
						</span>
					</h1>

					{/* <p style={{ fontSize: 24 }}> */}
					<p>
						Have your speech ready? Enter your <strong>Entrant ID</strong> to begin.
						<br />
						If you need some tips on how to record a great speech,{' '}
						<a
							href="https://www.webbyawards.com/winner-resources/5-word-speech-tips/"
							target="_blank"
							rel="noreferrer"
						>
							click here
						</a>
						. And don’t forget, your speech is a secret until we announce it to the public
						on May 13, 2024.
					</p>
					<br />

					<div className="form">
						<input
							ref={id}
							type="text"
							placeholder="ENTER ID HERE"
							onKeyPress={kp}
						/>

						<br />
						<br />

						<Button onClick={validate} wide>
              Get Started
						</Button>

						<div className="error">
							{error || (
								<span dangerouslySetInnerHTML={{ __html: '&nbsp;' }}></span>
							)}
						</div>

						<Help />
					</div>
				</div>
			</div>

			{loading && <Loader text="Logging in..." />}
		</>
	)
}
